import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import main from './main';
import privacy from './privacy';
import terms from './terms';
import imprint from './imprint';
import {Share} from './share';
import {BrowserRouter, Routes, Route} from "react-router-dom";


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route index element={main()}></Route>
                <Route path="/privacy" element={privacy()}></Route>
                <Route path="/imprint" element={imprint()}></Route>
                <Route path="/terms" element={terms()}></Route>
                <Route path="/s" element={<Share/>}></Route>
                <Route path="/x" element={<Share/>}></Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);




