import './main.css';
import React, {useEffect, useState} from "react";
import {Header} from "./header";


export const Share = () => {
    const checkIsAppInstalled = async () => {
        if ('getInstalledRelatedApps' in navigator) {
            const relatedApps = await navigator.getInstalledRelatedApps()
            return relatedApps.find((app) => {
                return app.id === "lol.rezepte";
            });
        }
        return false
    }

    const [isAppInstalled, setAppInstalled] = useState(false)

    useEffect(() => {
        const checkApp = async () => {
            setAppInstalled(await checkIsAppInstalled())
        }

        checkApp();
    }, [])

    useEffect(() => {
        const onVisibilityChange = async () => {
            if (!document.hidden) {
                setAppInstalled(await checkIsAppInstalled())
            }
        }
        document.addEventListener("visibilitychange", onVisibilityChange, false)
        return () => {
            document.removeEventListener("visibilitychange", onVisibilityChange)
        }
    });

    let content;
    if (isAppInstalled) {
        content = (
            <div className="legal">
                <div className="title-download">
                    Rezept erhalten
                </div>

                <div className="subtitle-download">
                    Öffne nun die App für die volle deutsche Gesundheitssystemexperience.
                </div>
                <div className="primary-button" onClick={() => {
                    window.open(window.location.href)
                }}>
                    Jetzt in App öffnen
                </div>
            </div>
        )
    } else {
        content = (
            <div className="legal">
                <div className="title-download">
                    rezepte.lol installieren
                </div>

                <div className="subtitle-download">
                    Mit Dir wurde ein Rezept geteilt. App installieren, um es zu öffnen.
                </div>
                <div className="download-button">
                    <a href='https://play.google.com/store/apps/details?id=lol.rezepte&gl=DE&utm_campaign=landingpage&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img
                            alt='Jetzt bei Google Play'
                            src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png'/>
                    </a>
                </div>
            </div>
        )
    }

    return (
        <div className="app">
            <Header hideContact={true}/>
            {content}
            <div className="spacer"/>
            <div className="app-footer">
                <a href="/imprint" className="footer-text">Impressum</a>
                <a href="/terms" className="footer-text">AGB</a>
                <a href="/privacy" className="footer-text">Datenschutz</a>
                <a href="https://github.com/rezepte-lol/community/discussions/categories/fragen-antworten"
                   className="footer-text">Community</a>
            </div>
        </div>
    );
}
