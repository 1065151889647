export const Header = (props) => {
    let contact;
    if (!props.hideContact) {
        contact = (
            <a href="mailto:huhu@rezepte.lol">
                <div className="primary-button">
                    <img className="hero-image" src={"/Flieger.svg"} alt="Send-Icon"/>
                    <div>Kontakt</div>
                </div>
            </a>
        )
    } else {
        contact = null
    }

    return (
        <div className="app-header">
            <a href="/" className="app-header-logo">
                <img className="header-icon" src={"/Logo.svg"} alt="Logo von rezepte.lol"/>
                <p className="title">rezepte.lol</p>
            </a>
            <span className="spacer"/>
            {contact}
        </div>
    );
}
