import {Link} from "react-router-dom";


import './main.css';
import {Header} from "./header";

function main() {
    return (
        <div className="app">
            <Header hideContact={false}/>

            <div className="hero-container">


                <div className="hero-text">

                    <div className="h1">
                        Die unkomplizierte Alternative für eRezepte
                    </div>

                    <div className="h2">
                        Blitzschnell & sicher eRezepte empfangen, teilen und einlösen.
                    </div>

                    <div className="download-button">
                        <a href='https://play.google.com/store/apps/details?id=lol.rezepte&gl=DE&utm_campaign=landingpage&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                            alt='Jetzt bei Google Play'
                            src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png'/></a>

                    </div>

                </div>

                <img className="hero-image" src="Hero.png"
                     alt="Hero-Element. Animation eines Workflows in rezepte.lol"/>
            </div>



            <div className="spacer"/>

            <div className="app-footer">
                <a href="/imprint" className="footer-text">Impressum</a>
                <a href="/terms" className="footer-text">AGB</a>
                <a href="/privacy" className="footer-text">Datenschutz</a>
                <a href="https://github.com/orgs/rezepte-lol/discussions" className="footer-text">Community</a>
            </div>
        </div>
    )

}

export default main;
