import './main.css';
import {Header} from "./header";

function Privacy() {
    return (
        <div className="app">
            <Header hideContact={false}/>

            <div className="legal">
                <div className="title-legal">
                    Datenschutz
                </div>

                <div className="date-legal">
                    Stand: November 2022
                </div>

                <div className="legal-text">
                    TL;DR: Alles Wichtige bleibt auf Deinem Gerät. Für das verschlüsselte Teilen von Rezepten verwenden
                    wir Services von Google.
                    Du kannst uns dabei helfen, die App zu verbessern, indem Du uns erlaubst, anonym Dein
                    Nutzer:innenverhalten zu tracken. Auch hierfür verwenden wir Google-Services.
                </div>

                <div className="paragraph-title">
                    Welche Schnittstellen des Telefons verwenden wir?
                </div>

                <div className="legal-text">
                    Um Rezepte mit der Kamera einscannen zu können, musst Du der App Zugriff auf die Kamera gewähren.

                </div>

                <div className="paragraph-title">
                    Welche Daten lesen wir aus, wenn Du ein Rezept scannst?
                </div>
                <div className="legal-text">
                    Jeder eRezept-Code enthält zwei Informationen: Einen Zugangscode (Access Code) und eine eindeutige
                    ID des Rezepts (Task ID).

                    Apotheken nehmen diese Informationen und übermitteln sie an den staatlichen Rezepteserver der
                    gematik (die Agentur des Bundes für die Gesundheits-Infrastruktur). Dafür erhalten sie dann Infos
                    wie Deinen Namen, Deine Versicherungsnummer, ob Du von einer Zuzahlung befreit bist und einiges
                    mehr. Vor allem aber: um welches Medikament es sich handelt. Wir sind keine Apotheke und dürfen das
                    nicht. Daher nutzen wir eine Texterkennungsoftware, um die Rezepte für Dich sinnvoll zu benennen.
                    Die heißt ML Kit und ist von Google.
                    <div className="external-link">
                        <a href="https://developers.google.com/ml-kit/terms">Mehr über ML Kit erfahren</a>
                    </div>
                </div>

                <div className="paragraph-title">
                    Wo speichern wir Deine Daten nach dem Importieren eines Rezepts?
                </div>
                <div className="legal-text">

                    Deine persönlichen Daten liegen ausschließlich auf Deinem Telefon. Mit allen Vor- und Nachteilen.
                    Die Vorteile liegen auf der Hand: Privatsphäre, es gibt nicht DEN einen Server der gehackt werden
                    kann etc. pp. Der Nachteil ist, wenn Dein Telefon weg ist, sind es Deine Daten auch.
                </div>
                <div className="legal-text">
                    <ul>
                        <li>Task ID</li>
                        <li>Access Code</li>
                        <li>Erstellungsdatum des Rezepts</li>
                        <li>Ausstellende Person</li>
                        <li>Name des Medikaments</li>
                        <li>Pharmazentralnummer (PZN)</li>
                    </ul>
                </div>
                <div className="legal-text">
                    Die Fotos von Deinen Rezepten schmeißen wir direkt nach dem Auslesen weg. Wenn Du Rezepte in der App
                    löscht, werden auch die zugehörigen Daten gelöscht.
                </div>

                <div className="paragraph-title">
                    Wie funktioniert das "sichere Teilen"?
                </div>
                <div className="legal-text">

                    In rezepte.lol bieten wir zwei Möglichkeiten, eRezepte zu teilen. Tippst Du auf "Teilen" öffnet sich
                    das Sharesheet mit allen Messengern, Social Media Diensten,
                    Mail-Programmen etc. die auf Deinem Gerät zur Verfügung stehen.
                    Wählst Du eine Option aus, fügen wir eine Rezeptkarte mit Name des eRezepts und dem Rezeptcode ein,
                    die Du als Bilddatei teilen kannst.
                    <br/>
                    Beim "Sicher teilen" werden alle zur Verfügung stehende Rezeptinformationen verschlüsselt auf einen
                    Server geladen und Du erhältst einen Link,
                    den Du anschließend mit einer beliebigen Person teilen kannst.
                    <br/>
                    Dieser Link lässt sich nur mit der rezepte.lol-App öffnen und ist eine kurze Zeit gültig.

                    <br/>
                    Um Dir diesen Service anbieten zu können, nutzen wir Google Cloud. Hierbei fallen Log-Dateien an,
                    die nach 30 Tagen gelöscht werden und die für die Qualitätssicherung des Services benötigt werden.
                    Diese Log-Dateien beinhalten einen Zeitstempel, IP-Adresse sowie von wo Du Dich mit dem Internet
                    verbunden hast (Provider).
                    Wir haben für die Verarbeitung und das Speichern der Daten ausschließlich Server in der Europäischen
                    Union (Datenhaltung: Belgien, Niederlande; Verarbeitung: Belgien) autorisiert.

                </div>


                <div className="paragraph-title">
                    Analytics
                </div>
                <div className="legal-text">

                    Um die App zu verbessern und zu überprüfen, ob die Funktionen reibungslos laufen, sind wir unter
                    anderem auf Tracking angewiesen.
                    Um das zu erreichen, speichern wir folgende Informationen:
                </div>
                <div className="legal-text">
                    <ul>
                        <li>Version Betriebssystem</li>
                        <li>Welche Aktionen Du in der App ausführst</li>
                    </ul>
                </div>
                <div className="legal-text">
                    So sehr wir uns freuen, wenn Du uns dabei hilfst, die App besser zu machen, so sehr verstehen wir
                    auch, wenn Du Tracking ablehnst. Das ist selbstverständlich möglich und Du kannst alle Funktionen
                    ohne Einschränkungen nutzen.
                </div>

                <div className="legal-text">
                    Für das Tracking verwenden wir Google Analytics. Hierbei werden keine IP-Adressen gespeichert.
                    <div className="external-link">
                        <a href="https://firebase.google.com/support/privacy">Mehr über Firebase erfahren</a>
                    </div>
                </div>
                <div className="legal-text">
                    Um über Abstürze informiert zu werden, nutzen wir Firebase Crashlytics.
                    <div className="external-link">
                        <a href="https://firebase.google.com/products/crashlytics">Mehr über Crashlytics erfahren</a>
                    </div>
                </div>
            </div>


            <div className="spacer"/>
            <div className="app-footer">
                <a href="/imprint" className="footer-text">Impressum</a>
                <a href="/terms" className="footer-text">AGB</a>
                <a href="/privacy" className="footer-text">Datenschutz</a>
                <a href="https://github.com/rezepte-lol/community/discussions/categories/fragen-antworten"
                   className="footer-text">Community</a>
            </div>
        </div>
    );
}

export default Privacy;










