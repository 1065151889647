import './main.css';
import {Header} from "./header";

function imprint() {
    return (
        <div className="app">

            <Header hideContact={false}/>

            <div className="legal">
                <div className="title-legal">
                    Impressum
                </div>

                <div className="date-legal">
                    Angaben gem. § 5 TMG
                </div>








                <p>
                   Let's Prescribe UG (haftungsbeschränkt)<br/>
                   Tempelhofer Damm 145<br/>
                                    12099 Berlin<br/>
                                    <a href="mailto:huhu@rezepte.lol">huhu@rezepte.lol</a><br/>
                                    <br/>
                                    Registergericht: Amtsgericht Charlottenburg (Berlin)<br/>
                                    Registernummer: HRB 251369 B<br/>
                                    Vertretungsberechtigter Geschäftsführer: Felix Handschuh<br/>
                                    <br/>


                                    <a className="external-link" href="https://www.linkedin.com/in/felix-🤝-handschuh-05205819/">Felix Handschuh</a>


                                    <a className="external-link" href="https://www.linkedin.com/in/tobias-schwerdtfeger/">Tobias Schwerdtfeger</a>
                </p>


            </div>
            <div className="spacer"/>
            <div className="app-footer">
                <a href="/imprint" className="footer-text">Impressum</a>
                <a href="/terms" className="footer-text">AGB</a>
                <a href="/privacy" className="footer-text">Datenschutz</a>
                <a href="https://github.com/rezepte-lol/community/discussions/categories/fragen-antworten"
                   className="footer-text">Community</a>
            </div>


        </div>


    )
        ;
}

export default imprint;










