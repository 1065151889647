import './main.css';
import {Header} from "./header";

function terms() {
    return (
        <div className="app">
            <Header hideContact={false}/>

            <div className="legal">
                <div className="title-legal">
                    Nutzungsbedingungen
                </div>

                <div className="date-legal">
                    Stand: November 2022
                </div>

                <div className="legal-text">
                    rezepte.lol ist eine App zum Importieren, Speichern und Teilen von eRezepten.

                    Die Nutzungsbedingungen gelten automatisch durch Nutzung der App. Es ist nicht gestattet, die App,
                    Teile der App oder unsere Marke in irgendeiner Weise zu kopieren oder zu verändern.

                    Es ist nicht gestattet, den Quellcode der App zu extrahieren, in eine andere Sprachen zu übersetzen
                    oder abgeleitete Versionen zu erstellen.

                    Wir arbeiten dafür, dass diese App so nützlich und effizient wie möglich ist. Aus diesem Grund
                    behalten wir uns das Recht vor, jederzeit und ohne Angabe von Gründen Änderungen an der App
                    vorzunehmen.

                    Die rezepte.lol-App speichert und verarbeitet personenbezogene Daten, die Du uns zur Verfügung
                    gestellt hast.
                    Es liegt in Deiner Verantwortung, das Telefon und den Zugriff auf die App sicher zu stellen.
                    Wir empfehlen Dir daher, Ihr Telefon nicht zu jailbreaken oder zu rooten, d.h.
                    Softwarebeschränkungen und -einschränkungen des offiziellen Betriebssystems Deines Geräts
                    aufzuheben.
                    Dies kann Dein Telefon anfällig für Malware/Viren/Schadprogramme machen, die Sicherheitsfunktionen
                    des Telefons beeinträchtigen und dazu führen, dass die rezepte.lol-App nicht mehr richtig
                    oder überhaupt nicht mehr funktioniert.

                    Die App nutzt Dienste von Drittanbietern:

                </div>

                <div className="legal-text">
                    <ul>
                        <li>
                            <div className="external-link">
                                <a href="https://policies.google.com/terms">Google Play Services</a>
                            </div>
                        </li>
                        <li>
                            <div className="external-link">
                                <a href="https://firebase.google.com/terms/analytics">Google Analytics für Firebase</a>
                            </div>
                        </li>
                        <li>
                            <div className="external-link">
                                <a href="https://firebase.google.com/terms/crashlytics">Firebase Crashlytics</a>
                            </div>
                        </li>
                        <li>
                            <div className="external-link">
                                <a href="https://cloud.google.com/terms?hl=de">Google Cloud</a>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="legal-text">
                    Wir bemühen uns um einen bestmöglichen Service in der App. Dennoch übernehmen wir keine Haftung für
                    fehlerhaft angezeigte Informationen in der App oder für direkt oder indirekte Verluste,
                    die Du erleidest, weil Du Dich auf Informationen in der App verlassen hast.

                    Es kann sein, dass wir die App irgendwann einmal aktualisieren möchten. Die App ist derzeit auf
                    Android
                    verfügbar -
                    die Anforderungen für das System (und für zusätzliche Systeme, auf die wir die Verfügbarkeit der App
                    ausweiten wollen) können sich ändern, und Du musst dafür die Updates herunterladen,
                    wenn du die App weiterhin nutzen willst. Wir können auch die Bereitstellung der App einstellen und
                    die
                    Nutzung jederzeit beenden, ohne Dich darüber zu informieren.
                    Sofern wir Dir nichts anderes mitteilen, enden bei einer Beendigung (a) die Dir in diesen
                    Bedingungen
                    gewährten Rechte und Lizenzen; (b) Du musst die Nutzung der App einstellen und sie (falls
                    erforderlich)
                    von Deinem Gerät löschen.

                    <br/>

                    Unsere Allgemeinen Geschäftsbedingungen werden von Zeit zu Zeit aktualisiert. Bitte überprüfe daher
                    diese Seite regelmäßig auf Änderungen.
                </div>

                <div className="paragraph-title">
                    Anbieter
                </div>

                <div className="legal-text">
                    Anbieter der App sind Tobias Schwerdtfeger und Felix Handschuh.

                </div>

                <div className="paragraph-title">
                    Nutzungsrechte
                </div>
                <div className="legal-text">
                    Wir räumen den Nutzer:innen das einfache, widerrufliche, nicht unterlizenzierbare, nicht
                    übertragbare und inhaltlich auf die bestimmungsgemäße Nutzung der App beschränkte Recht ein, diese
                    App nach Maßgabe dieser Nutzungsbedingungen für eigene, nicht-kommerzielle Zwecke zu nutzen.
                    Sollten eine oder mehrere Bestimmungen dieser Nutzungsbedingungen unwirksam, undurchführbar oder
                    nicht durchsetzbar sein oder werden, so werden die übrigen Bestimmungen davon nicht berührt.
                    Es gilt das materielle Recht der Bundesrepublik Deutschland.
                </div>
            </div>

            <div className="spacer"/>
            <div className="app-footer">
                <a href="/imprint" className="footer-text">Impressum</a>
                <a href="/terms" className="footer-text">AGB</a>
                <a href="/privacy" className="footer-text">Datenschutz</a>
                <a href="https://github.com/rezepte-lol/community/discussions/categories/fragen-antworten"
                   className="footer-text">Community</a>
            </div>
        </div>
    );
}

export default terms;










